define(['app'], (app) => {

  const subscriptionComponentInfoMessage = () => {
    const component = {};

    var _config = {
      selectors: {
        component: '[data-component=productPrice]',
        rrp: '[data-product-price=rrp]',
        price: '[data-product-price=price]',
        saving: '[data-product-price=saving]',
        schemaPrice: '[data-schema=price]',
        recurringPayment: '[data-product-price=recurringPayment]',
        fromPrice: '[data-product-price="fromPrice"]',
        professionalPricingBanner: '[data-product-price=professionalPricingBanner]',
        optionTerm: '[data-product-price=paymentOptionTerm]',
        monthSingular: '[data-product-price=optionMonthTextSingular]',
        monthPlural: '[data-product-price=optionMonthTextPlural]',
        recurringPaymentText: '[data-product-price=recurringPaymentText]',
        fastTrackSwitch: '.fastTrack_onProductPage'
      },
      subscribeChannels: {
        subscriptionTerm: 'subscriptionComponent/subscriptionTerm',
        subscriptionType: 'subscriptionComponent/subscriptionType'
      }
    };

    const _init = (element) => {
      component.element = element;

      app.subscribe(component.config.subscribeChannels.subscriptionTerm, component.getNewTerm);
      app.subscribe(component.config.subscribeChannels.subscriptionType, component.getNewSubscriptionType);

      return component;
    };

    const _getNewTerm = (subscriptionTerm) => {
      let termElement = component.element.querySelector(component.config.selectors.optionTerm);
      let monthSingular = component.element.querySelector(component.config.selectors.monthSingular);
      let monthPlural = component.element.querySelector(component.config.selectors.monthPlural);

      if (termElement && monthSingular && monthPlural) {
        termElement.innerHTML = subscriptionTerm;

        if (subscriptionTerm === '1') {
          monthSingular.classList.remove('hideMonth');
          if (!monthPlural.classList.contains('hideMonth')) {
            monthPlural.classList.add('hideMonth');
          }
        } else {
          monthPlural.classList.remove('hideMonth');
          if (!monthSingular.classList.contains('hideMonth')) {
            monthSingular.classList.add('hideMonth');
          }
        }
      }
    };

    const _getNewSubscriptionType = (subscriptionType) => {
      let recurringPaymentTextEl = component.element.querySelector(component.config.selectors.recurringPaymentText);
      if (recurringPaymentTextEl) {
        if (subscriptionType === 'recurring' && !recurringPaymentTextEl.classList.contains('hideRecurringPaymentText')) {
          recurringPaymentTextEl.classList.add('hideRecurringPaymentText');
        }
        else if (subscriptionType === 'upfront' && recurringPaymentTextEl.classList.contains('hideRecurringPaymentText')) {
          recurringPaymentTextEl.classList.remove('hideRecurringPaymentText');
        }
      }
    };

    component.config = _config;
    component.init = _init;
    component.getNewTerm = _getNewTerm;
    component.getNewSubscriptionType = _getNewSubscriptionType;

    return component;
  };

  return subscriptionComponentInfoMessage;
});
